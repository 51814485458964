<style>

</style>

<script>
import {get_acsrf_token} from "@/methods";

export default {
  name: "BanFileUpload",
  props: ['banForm', 'banlist_id', 'createMode'],
  methods: {
    filePrompt() {
      document.getElementById('file').click();
    },
    async addFile(event) {
      let toast = this.$toast;
      let files = event.target.files;
      files.forEach((file) => {
        if (this.banForm.length >= 5) {
          this.$toast.error(this.$t('support.request.upload.errors.count', {file: file.name}));
          return;
        }
        if (this.banForm.filter((e) => {
          if (e.name === file.name) return e;
        }).length !== 0) return;
        let actualFileSize = file.size / 1024 / 1024; // MiB
        if (actualFileSize > 10) {
          this.$toast.error(this.$t('support.request.upload.errors.size', {file: file.name}));
        } else {
          this.banForm.push({
            object: file,
            size: actualFileSize.toPrecision(2),
            name: file.name,
            processing: true,
            error: false,
            uploaded: false,
            identifier: null,
            resource_id: null
          });
          this.invokeFileUpload(file.name);
        }
      });
      this.$refs.file.value = null;
    },
    removeFile(fileName) {
      let index = this.banForm.map((e) => {
        return e.name;
      }).indexOf(fileName);
      if (index !== -1) this.banForm.splice(index, 1);
    },
    async invokeFileUpload(fileName) {
      let index = this.banForm.map((e) => {
        return e.name;
      }).indexOf(fileName);
      if (index === -1) return;
      try {
        let file = this.banForm[index];

        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_id: this.banlist_id
        };

        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/upload`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        let data;
        if (!response.ok) {
          if (response.status === 400) {
            data = await response.json();
            if (data.error === 'exhausted') {
              this.$swal({
                icon: 'warning',
                text: this.$t('error.gryphon.quota.message'),
                title: this.$t('error.gryphon.quota.title')
              });
              throw new Error('gryphon blocked');
            }
          } else {
            throw new Error('failed to request resource id');
          }
        } else {
          data = await response.json();
          file.resource_id = data.resource_id;
          if (data.storage_warning) {
            let result = await this.$swal({
              icon: 'warning',
              text: this.$t('error.gryphon.quota_warning.message'),
              title: this.$t('error.gryphon.quota_warning.title'),
              showCancelButton: true,
              confirmButtonText: this.$t('error.gryphon.quota_warning.confirm'),
              confirmButtonColor: '#f46a6a',
              cancelButtonColor: '#c3cbe4',
            });
            if (!result.isConfirmed) throw new Error('cancelled');
          }
        }

        payload = new FormData();
        payload.append('file', file.object);
        payload.append('upload_token', data.upload_token);

        response = await fetch(data.upload_url, {
          method: 'POST',
          body: payload,
          credentials: 'include'
        });
        if (!response.ok) throw new Error('upload failed');
        file.processing = false;
        file.uploaded = true;

      } catch (error) {
        this.removeFile(fileName);
        if (error !== 'cancelled') this.$toast.error(this.$t('error.server.generic.message'));
      }
    },
  },
}
</script>
<template>
  <div>
    <div class="row" v-if="createMode">
      <div class="col">
        <h5 class="d-inline">{{ $t('banmanager.add.evidence.title') }}</h5>
      </div>
      <div class="col">
        <button
            id="banFormFiles"
            v-if="banForm.length < 5"
            class="btn btn-sm btn-rounded btn-primary float-left d-inline"
            v-on:click="filePrompt()">
          <i class="fad fa-cloud-upload"/>
          Upload
        </button>
      </div>
    </div>

    <hr v-if="createMode" style="margin-top: 8px">
    <p v-if="createMode">{{ $t('banmanager.add.evidence.upload.label') }}</p>
    <!--    Place all files in one column (old from parent-component/new).-->
    <template v-if="banForm.length !== 0">
      <ul class="ml-0 pl-0 mt-2" style="list-style: none;">
        <li v-for="file in banForm" v-bind:key="file.name+Math.random()" class="text-truncate">
          <div v-if="file.size">
          <i class="fas fa-times-hexagon text-danger" style="cursor: pointer;"
             v-on:click="removeFile(file.name)"></i>
          <span class="ml-1">
                <i v-if="file.processing" class="fas fa-spinner fa-spin mr-1"></i>
                <template v-if="!file.error">
                  <span v-if="!file.uploaded" class="badge badge-info">{{ file.size }} MiB</span>
                  <span v-else-if="file.uploaded" class="badge badge-success">
                    <i class="fas fa-check"></i>
                  </span>
                </template>
                <span v-else-if="file.error"
                      class="badge badge-danger">{{ $t('banmanager.add.evidence.upload.errors.upload') }}</span>
                <span class="ml-1">{{ file.name }}</span>
              </span>
          </div>
        </li>
      </ul>
    </template>
    <input id="file" ref="file" multiple style="display: none;" type="file" v-on:change="addFile($event)">
    <div class="row">
      <div class="col">
        <button
            id="banFormFiles"
            v-if="banForm.length < 5 && !createMode"
            class="btn btn-sm btn-rounded btn-primary float-left d-inline"
            v-on:click="filePrompt()">
          <i class="fad fa-cloud-upload"/>
          Upload
        </button>
        <template v-if="banForm.length === 0">
          <br>
          <br>
          <small>
            <i class="text-muted text-small">{{ $t('banmanager.add.evidence.upload.none') }}</i>
          </small>
        </template>
      </div>
    </div>
  </div>
</template>